.dotted .dropdown-toggle:after {
    content  : "...";
    border   : none;
    color    : #949494;
    font-size: 26px;
}

#story-modal .modal-dialog {
    max-width: 760px;
}

a.navbar-brand {
    @include tablet {
        position: relative;
        top     : -5px;
        left    : 8px;
    }
}

.react-list {
    position        : absolute;
    top             : -60px;
    left            : 0;
    display         : flex;
    align-items     : center;
    background-color: #ffffff;
    @include border-radius(30px);
    padding: 4px 15px 5px;
    @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, .2));
    visibility: hidden;
    opacity   : 0;
    @include transform(scale(1.1));

    -webkit-transform-origin: left;
    -ms-transform-origin    : left;
    transform-origin        : left;
    -webkit-transition      : all 0.3s ease-in-out;
    transition              : all 0.3s ease-in-out;

    li {
        display           : inline-block;
        margin-right      : 4px;
        opacity           : 0;
        -webkit-transform : scale(1.1);
        -ms-transform     : scale(1.1);
        transform         : scale(1.1);
        -webkit-transition: all 0.3s ease-in-out;
        transition        : all 0.3s ease-in-out;
    }
}

.post-react {
    position: relative;
    z-index : 1;

    &:hover .react-list {
        visibility: visible;
        @include opacity(1);

        li {
            @include opacity(1);
        }

    }
}


.single-entry {
    border: 1px solid $border-color;
    @include border-radius(5px);
    @include box-shadow(0 2px 10px rgba(0, 0, 0, .05));
    margin-bottom: 20px;
    overflow     : hidden;

    &.timeline-carousel {
        overflow: inherit;
    }

    .entry-inner {
        padding         : 20px;
        background-color: $white;

        .entry-header {
            h3 {
                font-size: 16px;

                a {
                    margin-left: 15px;
                    color      : $primary;
                    font-size  : 17px;
                }
            }

            .dropdown-toggle:after {
                content  : "...";
                border   : none;
                color    : $text-muted;
                font-size: 26px;
            }

            .ava-desc {
                i {
                    font-size: 10px;
                    position : relative;
                    top      : -2px;
                }
            }
        }

        .entry-content {
            p strong {
                color: $headings-color;
            }

            .post-img-list {
                a.more-items {
                    position: absolute;
                    right: 23%;
                    bottom: 85px;
                    z-index: 9;
                    color: #fff;
                    text-transform: capitalize;
                    background: rgba(91, 47, 249, 0.85);
                    padding: 10px;
                    border-radius: 50%;
                    width: 85px;
                    height: 85px;
                    display: table;
                    @extend .fw-7;
                    span.gallery-more {
                        text-align: center;
                        display: table-cell;
                        line-height: 2;
                        vertical-align: middle;
                        i {
                            display: block;
                        }
                    }
                }
            }
        }

        .entry-meta {
            ul {
                @include list-clear;

                li {
                    display: inline-block;
                }

            }

            span.react-count {
                font-size  : 13px;
                color      : $secondary;
                margin-left: 10px;
                font-weight: 600;
                position   : relative;
                top        : 2px;

            }

            .post-comment {
                li {
                    margin-left: 10px;

                    a span {
                        margin-right: 5px;
                    }
                }
            }
        }

        .entry-footer {
            span.entry-react {
                font-weight: 600;

                i {
                    margin-right: 5px;
                }

                &:hover a {
                    color: $primary;
                }
            }

            ul.site-share {
                @include list-clear;
                text-align: center;

                li {
                    display: inline-block;
                    margin : 0 3px;
                }
            }
        }
    }

    .comment-form {
        background-color: $light-gray;

        .btn {
            border          : 1px solid $border-color;
            border-radius   : 50px;
            text-align      : left;
            padding         : 8px 26px;
            background-color: $white;
            color           : $text-muted;
        }
    }

    &.newsfeed-form {
        position: relative;

        .btn-trans {
            font-size: 18px;
            padding  : 37px 30px;

            img {
                display   : block;
                margin-top: 20px;
            }
        }

        .post-options {
            position: absolute;
            top     : 20px;
            right   : 20px;

            button {
                display       : block;
                margin-bottom : 15px;
                text-transform: capitalize;
                width         : 175px;
                @include border-radius(50px);
                height: 50px;

                &:hover {
                    color: $secondary;
                }

                img {
                    margin-right: 8px;
                }
            }

            @include mobile {
                position: relative;
                right   : auto;
                top     : auto;

                button {
                    display: inline-block;
                    width  : 145px;
                }
            }
        }

        .entry-status {
            .dropdown-toggle {
                i {
                    color       : #afadad;
                    margin-right: 5px;
                }

                &:after {
                    content       : "";
                    border-top    : .3em solid;
                    border-right  : .3em solid transparent;
                    border-bottom : 0;
                    border-left   : .3em solid transparent;
                    font-size     : 18px;
                    vertical-align: 2px;
                }

            }

            .dropdown-item {
                font-size: 13px;

                i {
                    margin-right: 5px;

                }
            }
        }

    }

    .follow-peoples {
        .single-follow {
            margin-bottom: 20px;

            h3 {
                span {
                    display: block;
                    @extend .fw-4;
                    font-size : 14px;
                    margin-top: 5px;
                }
            }

            .btn {
                @include border-radius(50px);
                @extend .fw-6;
                padding: 7px 20px;
            }
        }

    }

    .show-more a {
        color         : $primary;
        text-transform: capitalize;
    }
}

.comment-wrap {
    max-width    : 80%;
    margin-bottom: 0;

    @include mobile {
        max-width: 97%;
    }

    .react-list {
        top: -45px;
    }

    .comment-content {
        padding      : 5px 20px 5px;
        position     : relative;
        border       : 1px solid $border-color;
        border-radius: 50px;

        a {
            position  : absolute;
            top       : 3px;
            right     : -20px;
            width     : 190px;
            text-align: right;

        }

        p {
            margin     : 0;
            line-height: 1.5;
        }
    }

    ul.comment-item-nested.list-unstyled {
        padding-left: 45px;
    }
}