.pagetab-head {
    h3 {
        @extend .fw-7;
    }
    span {
        width : 32px;
        height: 32px;
        @include border-radius(50%);
        text-align      : center;
        display         : inline-block;
        background-color: $white-light;
        margin-right    : 5px;
        line-height: 28px;
        i {
            font-size: 13px;
            color    : $primary;
        }
    }
    .btn i {
        margin-right: 5px;
    }
}

.smp-item {
    margin-bottom: 10px;
    @include transition();
    padding: 10px;
    cursor : pointer;

    .smp-info {
        margin-left: 15px;

        h4.h6 {
            margin: 0 0 5px;

            a {
                color: $headings-color;

                &:hover {
                    color: $primary;
                }
            }
        }

        i {
            margin-right: 5px;
        }
    }

    &:hover {
        background-color: $light-gray;
    }
}

.sugg-card {
    text-align: center;

    img {
        width: 111px;
    }

    h4 {
        font-size: 14px;
        margin   : 0;
    }

    .btn {
        padding   : 3px 10px;
        margin-top: 10px;
    }
}

.custom-modal-form {
    .modal-header {
        text-align: center;
    }

    form {
        .form-group {
            margin-bottom: 15px;

            label {
                @extend .fw-6;
                margin-bottom: 10px;
            }

            input {
                border: none;
            }

            textarea {
                min-height: 130px;
            }
        }
    }
}
.widget .pagetab-head span{line-height: 30px;}