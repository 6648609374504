
// MY THEME COLORS 
$light-blue: #B136EFFF;
$secondary: #636363;
$brand-blue: #00415d;
$white: #fff;
$white-light: #DFD9F6;
$brand-mute: #949494;
$headings-color: #101010;
$light-gray: #fafafa;
$gray: #adaaaa;
$colorpink: rgb(204, 19, 164)

