.profile-cover {
    position     : relative;
    overflow     : hidden;
    border-radius: 15px;

    button.edit-cover {
        position  : absolute;
        right     : 17px;
        top       : 12px;
        background: #fff;
        color     : $secondary;

        i {
            margin-right: 5px;
        }
    }
}

.profile-header {
    padding            : 215px 0 0px;
    background-size    : cover;
    background-position: center center;

    .profile-avatar {
        @extend .overly;
        padding-bottom: 25px;

        &::before {
            background: rgb(16, 16, 16);
            background: linear-gradient(0deg, rgba(16, 16, 16, .6) 0%, rgba(16, 16, 16, 0.569362779291404) 50%, rgba(0, 212, 255, 0) 100%);
            height    : 262px;
            top       : auto;
            bottom    : 0;
        }

        .avatar {
            border: 3px solid $white;
            @include border-radius(50%);
            margin-right: 30px;

        }

        h3 {
            color        : $white;
            margin-bottom: 20px;
        }

        .btn {
            padding: 10px 20px;
            color  : $white;

            i {
                margin-right: 7px;
            }
        }

        @include mobile {
            .avatar {
                margin-right: 20px;
            }

            h3 {
                font-size: 27px;
            }
        }
    }
}

.profile-nav {
    ul li {
        @extend .overly;

        &::before {
            height: 3px;
            @include opacity(0);
            width    : 70%;
            left     : 50%;
            transform: translateX(-50%);
            @include border-radius(10px);
        }

        a.nav-link {
            font-weight: 700;
            color      : $headings-color;
            font-size  : 16px;
            padding    : 15px;

            @include mobile {
                font-size: 13px;
                padding  : 12px;
            }
        }

        &.active::before {
            @include opacity(1);
        }
    }

    .nav-link {
        font-weight: 700;
    }
}

.profile-content {
    .comment-wrap {
        max-width: 95%;
    }

    .single-entry.newsfeed-form .post-options button {
        width        : 145px;
        font-size    : 12px;
        margin-bottom: 12px;
        height       : 45px;
    }

    .single-entry.newsfeed-form .btn-trans {
        font-size: 14px;
        padding  : 20px 10px;
    }
}

.intro-widget {
    h4 {
        font-weight  : 700;
        margin-bottom: 20px;
    }
}

.profile-pic {
    width : 140px;
    height: 140px;
}

.edit-profile-modal {
    .modal-body>div {
        margin-bottom: 30px;
    }

    .pm-title {
        font-size    : 18px;
        margin-bottom: 30px;
    }

    .btn {
        background-color: #EEE9FF;
        padding         : 5px 20px;
        color           : $primary;
        text-transform  : capitalize;
        margin-left     : 20px;
    }

    .edit-profile__bio {
        p {
            text-align: center;
            max-width : 280px;
            margin    : 0 auto;
        }
    }
}

.friends-request .card {
    padding   : 10px;
    margin    : 0 0 20px;
    text-align: center;

    h6 {
        margin-bottom: 5px;

        a {
            color: $headings-color;
        }
    }

    .btn {
        margin-bottom: 5px;
    }

    .card-control {
        margin-top: 10px;
    }

    &:hover h6 a {
        color: $primary;
    }
}

.ct-tab {
    .nav-tabs {
        border-bottom: none;

        .nav-link {
            border     : 0 none;
            font-weight: 700;
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            color           : $primary;
            background-color: transparent;
            border-color    : transparent;
        }
    }
}

.single-photo {
    display : inline-block;
    width   : 25%;
    position: relative;
    margin  : 0 0 15px;
    padding : 0 7px;

    >img {
        width: 100%;
    }

    .post-controls.dropdown.dotted {
        position: absolute;
        right   : 12px;
        top     : 0px;

        .nav-link {
            padding: 0;
        }
    }

    .dropdown-toggle:after {
        color        : #fff;
        line-height  : .7;
        background   : #B4B6B8;
        width        : 16px;
        text-align   : center;
        font-size    : 12px;
        height       : 16px;
        border-radius: 50%;
    }
}

.album-card {
    .dropdown-toggle:after {
        color        : #fff;
        line-height  : .7;
        background   : #B4B6B8;
        text-align   : center;
        font-size    : 21px;
        width        : 28px;
        height       : 28px;
        border-radius: 50%;
    }

    .post-controls.dropdown.dotted {
        position: absolute;
        right   : 10px;
        top     : 10px;

        .nav-link {
            padding: 0;
        }
    }
}

.card.album-create-card {
    min-height: 217px;

    a.create-album {
        height       : 140px;
        background   : #c4c4c4;
        display      : table;
        text-align   : center;
        margin-bottom: 17px;
        border-radius: 10px;
        cursor       : pointer;

        i {
            display       : table-cell;
            vertical-align: middle;
            color         : #fff;
            font-size     : 25px;
        }
    }
}

#createAlbum .post-inner {
    display: block;
}

.card-inn-lists .album-card {
    margin-right: 10px;
}